import './showlessons.css';
import './home.css';
import { useState, useEffect } from 'react';
import certificate from '../header/images/certificate.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSwatchbook, faChevronDown, faLock, faClipboardCheck, faLockOpen, faGripLines, faFileLines, faBullseye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import badge from '../imagesHome/badge.png';
import { faWebAwesome } from '@fortawesome/free-brands-svg-icons'; 

export const Showlessons = () => {

  //state pentru sageata care ascunde lectiile
  const [showLessonsMain, setShowLessonsMain] = useState(true);

  //state pentru a ascunde lectiile

  const [showChapterOne, setShowChapterOne] = useState(false);

  const [showChapterTwo, setShowChapterTwo] = useState(false);

  const showingChapterOne = () => {
    setShowChapterOne(!showChapterOne);
  }

  const showingChapterTwo = () => {
    setShowChapterTwo(!showChapterTwo);
  }

  const unhideLessons = () => {
    setShowLessonsMain(!showLessonsMain);
}

  const location = useLocation();
  const { updatedProgress } = location.state || {};

  // Inițial, prima lecție este deblocată, restul sunt blocate
  const [lessonProgress, setLessonProgress] = useState({
    1: { unlocked: true, completed: false },  // Prima lecție este întotdeauna deblocată
    2: { unlocked: false, completed: false },
    3: { unlocked: false, completed: false },
    4: { unlocked: false, completed: false },
    5: { unlocked: false, completed: false },
  });

  // Dacă există progres actualizat, se actualizează starea lecțiilor
  useEffect(() => {
    if (updatedProgress) {
      setLessonProgress(prevProgress => ({
        ...prevProgress,
        ...updatedProgress
      }));
    }
  }, [updatedProgress]);

  const tothelesson = useNavigate();

  // Verifică dacă lecția este deblocată
  const handleLessonClick = (chapterId, lessonId) => {
    if (lessonProgress[lessonId].unlocked) {
      tothelesson('/chaptercontainer', { state: { chapterId, lessonId } });
    } else {
      alert('Această lecție este blocată. Finalizează lecția anterioară pentru a o debloca.');
    }
  };

  return (
    <div className='container-show-lessons'>
      <div className="colored-header-lesson py-4 rounded-4 d-flex flex-column justify-content-center align-items-center" onClick={unhideLessons}>
        <div className='d-flex flex-row'>
          <FontAwesomeIcon icon={faSwatchbook} className='dimension-headers-lessons text-white mx-4 p-2 rounded-4' />
          <div className='text-white fs-4 fw-bold mx-1'>Bazele contabilitatii</div>
          <FontAwesomeIcon icon={faChevronDown} className={`text-white mx-4 p-2 rounded-4 ${
          showLessonsMain ? 'dimension-headers-lessons-1' : 'dimension-headers-lessons-rotate'
        }`} />
        </div>
        <div className='text-lesson-presentation'>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia officia, unde molestias ratione fugiat repudiandae laborum repellendus? Dolor illo, error, impedit culpa fugit, quis dicta amet repellat ullam nihil eveniet?</p>
        </div>
        <div>Progres bar</div>       
      </div>

      {showLessonsMain &&
      <div>

      
          
        
          <div className='chapter-container' onClick={showingChapterOne}>
            <FontAwesomeIcon icon={faLockOpen} style={{color: 'white', width: '20px', height: '20px'}} />
            <span>Capitol</span>
            <FontAwesomeIcon icon={faGripLines} className={showChapterOne?'dbl-line-vertical':'dbl-line-horizontal'} style={{color: 'white', width: '25px', height: '25px'}} />
          </div>

        
        {/* Lecția 1 - Este întotdeauna deblocată */}
        <div className={showChapterOne?'displaying-none-chapters':'displaying-show-chapters'}>
       
          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Scopul contabilitatii</span>
                </div>
                <FontAwesomeIcon icon={faLockOpen} style={{color: 'rgb(22, 199, 158)', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 1)} disabled={!lessonProgress[1].unlocked}>
                {lessonProgress[1].completed ? 'Lectie finalizata' : 'Start'}
              </button>
            </div>
          </div>


          {/* Lecția 3 */}
          <div className='new-lesson' style={{opacity: '0.6'}}>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Obiectul contabilitatii</span>
                </div>
                <FontAwesomeIcon icon={faWebAwesome} style={{color: 'rgb(214, 173, 9)', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>


          {/* Lecția 3 */}
          <div className='new-lesson' style={{opacity: '0.6'}}>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Patrimoniul</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'rgb(22, 199, 158)', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          <div className='new-lesson' style={{opacity: '0.6'}}>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 4</span>
                </div>
                <FontAwesomeIcon icon={faWebAwesome} style={{color: 'rgb(214, 173, 9)', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

        

          <div className='new-lesson' style={{opacity: '0.6'}}>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faClipboardCheck} style={{color: 'rgb(70,130,180)'}} className='symbol-item-lesson' />
                  <span className='name-lesson'>Practica</span>
                </div>
                <FontAwesomeIcon icon={faWebAwesome} style={{color: 'rgb(214, 173, 9)', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          <div className='new-lesson' style={{opacity: '0.6'}}>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 5</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'rgb(22, 199, 158)', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          <div className='new-lesson' style={{opacity: '0.6'}}>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 6</span>
                </div>
                <FontAwesomeIcon icon={faWebAwesome} style={{color: 'rgb(214, 173, 9)', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          {/* Lecția 4 */}
          <div className='new-lesson' style={{opacity: '0.6'}}>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 7</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'rgb(22, 199, 158)', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          {/* Lecția 5 */}
          <div className='new-lesson' style={{opacity: '0.6'}}>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faBullseye} style={{color: 'rgb(219,112,147)'}} className='symbol-item-lesson' />
                  <span className='name-lesson'>Test final de capitol</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'rgb(22, 199, 158)', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 5)} disabled={!lessonProgress[5].unlocked}>
                {lessonProgress[5].unlocked ? 'Start' : 'Lectie blocata'}
                
              </button>
            </div>
          </div>

          <button 
          style={{margin: '0 auto', width: '100%', opacity: '0.4', border: 'none', borderRadius: '10px', backgroundColor: 'rgb(22, 199, 158)'}}>
          <p>Nivel incepator</p>
          <img src={badge} style={{width: '150px'}} alt='badge' />
        </button>
        </div>

       

      


        <div className='chapter-container' onClick={showingChapterTwo}>
            <FontAwesomeIcon icon={faLock} style={{color: 'white', width: '20px', height: '20px'}} />
            <span>Capitol</span>
            <FontAwesomeIcon icon={faGripLines} className={showChapterTwo?'dbl-line-vertical':'dbl-line-horizontal'} style={{color: 'white', width: '25px', height: '25px'}} />
        </div>

        <div className={showChapterTwo?'displaying-none-chapters':'displaying-show-chapters'}>
            

        <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 1</span>
                </div>
                <FontAwesomeIcon icon={faLockOpen} style={{color: 'rgb(22, 199, 158)', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 1)} disabled={!lessonProgress[1].unlocked}>
                {lessonProgress[1].completed ? 'Lectie finalizata' : 'Start'}
              </button>
            </div>
          </div>


          {/* Lecția 3 */}
          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 2</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>


          {/* Lecția 3 */}
          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 3</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 4</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

        

          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faClipboardCheck} style={{color: 'rgb(70,130,180)'}} className='symbol-item-lesson' />
                  <span className='name-lesson'>Practica</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 5</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 6</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          {/* Lecția 4 */}
          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 7</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          {/* Lecția 5 */}
          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faBullseye} style={{color: 'rgb(219,112,147)'}} className='symbol-item-lesson' />
                  <span className='name-lesson'>Test final de capitol</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 5)} disabled={!lessonProgress[5].unlocked}>
                {lessonProgress[5].unlocked ? 'Start' : 'Lectie blocata'}
                
              </button>
            </div>
          </div>

          <button 
          style={{margin: '0 auto', width: '100%', opacity: '0.4', border: 'none', borderRadius: '10px', backgroundColor: 'rgb(22, 199, 158)'}}>
          <p>Nivel incepator</p>
          <img src={badge} style={{width: '150px'}} alt='badge' />
        </button>


        </div>


        <div className='chapter-container' onClick={showingChapterTwo}>
            <FontAwesomeIcon icon={faLock} style={{color: 'white', width: '20px', height: '20px'}} />
            <span>Capitol</span>
            <FontAwesomeIcon icon={faGripLines} className={showChapterTwo?'dbl-line-vertical':'dbl-line-horizontal'} style={{color: 'white', width: '25px', height: '25px'}} />
        </div>

        <div className={showChapterTwo?'displaying-none-chapters':'displaying-show-chapters'}>
            

        <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 1</span>
                </div>
                <FontAwesomeIcon icon={faLockOpen} style={{color: 'rgb(22, 199, 158)', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 1)} disabled={!lessonProgress[1].unlocked}>
                {lessonProgress[1].completed ? 'Lectie finalizata' : 'Start'}
              </button>
            </div>
          </div>


          {/* Lecția 3 */}
          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 2</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>


          {/* Lecția 3 */}
          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 3</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 4</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

        

          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faClipboardCheck} style={{color: 'rgb(70,130,180)'}} className='symbol-item-lesson' />
                  <span className='name-lesson'>Practica</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 5</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 6</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          {/* Lecția 4 */}
          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faFileLines} className='symbol-item-lesson' />
                  <span className='name-lesson'>Lectia 7</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 4)} disabled={!lessonProgress[4].unlocked}>
                {lessonProgress[4].unlocked ? 'Start' : 'Lectie blocata'}
              </button>
            </div>
          </div>

          {/* Lecția 5 */}
          <div className='new-lesson'>
            <div className='lesson-show-main'>
              <div className='symbol-lesson'>
                <div className='align-items-lessons-inside-show'>
                  <FontAwesomeIcon icon={faBullseye} style={{color: 'rgb(219,112,147)'}} className='symbol-item-lesson' />
                  <span className='name-lesson'>Test final de capitol</span>
                </div>
                <FontAwesomeIcon icon={faLock} style={{color: 'gray', width: '20px', height: '20px'}} />
              </div>
              <button onClick={() => handleLessonClick(1, 5)} disabled={!lessonProgress[5].unlocked}>
                {lessonProgress[5].unlocked ? 'Start' : 'Lectie blocata'}
                
              </button>
            </div>
          </div>

          <button 
          style={{margin: '0 auto', width: '100%', opacity: '0.4', border: 'none', borderRadius: '10px', backgroundColor: 'rgb(22, 199, 158)'}}>
          <p>Nivel incepator</p>
          <img src={badge} style={{width: '150px'}} alt='badge' />
        </button>


        </div>

     

     

      </div>

      
    }

    

      <div className='obtain-certificate'>
        <img src={certificate} alt="certificate" className="disabled-image" />
        <h4>Obtine diploma de bazele contabilitii</h4>
        <p>Te descurci bine, continua pana cand optii diploma!</p>
        <button className='buton-diploma'>Revendica-ti diploma</button>
      </div>
      <Link to='/diploma'>Diploma</Link>

     
    </div>
  );
};
