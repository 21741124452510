import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './paymentform.css';

// Stripe public key (test mode)
const stripePromise = loadStripe('pk_test_51QCzkaLrf6T4kadVj3jK36445mvH1zt6dHA69rhVGk42DIgkYvB0IhI1YyXlLenJ7skRpLesLZgeIfJkn9DgR1Yl00tgBSyLBT');

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    const cardElement = elements.getElement(CardElement);

    try {
      // Cerere la backend pentru a obține client secret
      const response = await fetch('http://localhost:5000/api/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: 50 }) // Suma pentru plată
      });
      
      const { clientSecret } = await response.json();

      // Confirmarea plății cu Stripe
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (result.error) {
        setError(result.error.message);
        setSuccessMessage('');
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          setSuccessMessage('Plata a fost finalizată cu succes!');
          setError('');
        }
      }
    } catch (error) {
      setError('A apărut o eroare la procesarea plății.');
      setSuccessMessage('Platit');
    }

    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <h2>Introdu detaliile cardului</h2>
      <div className="card-details">
        <CardElement 
          options={{
            style: {
              base: {
                fontSize: '18px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </div>
      <button type="submit" disabled={!stripe || isProcessing} className="submit-button">
        {isProcessing ? <span className="spinner"></span> : 'Plătește acum'}
      </button>
      {error && <div className="error-message">{error}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
    </form>
  );
};

// Wrapper pentru a încărca stripePromise
const StripeWrapper = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

export default StripeWrapper;
