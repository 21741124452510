import './userpage.css';
import React, { useEffect, useState } from 'react';

const UserPage = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Funcția pentru a obține datele utilizatorului
    const fetchUserData = async () => {
      try {
        const response = await fetch('http://localhost:5000/api/user', {
          method: 'GET',
          credentials: 'include',  // Asigură-te că trimiți cookie-urile
        });
        if (response.ok) {
          const data = await response.json();
          setUserData(data);
        } else {
          console.error('Eroare la preluarea datelor utilizatorului.');
        }
      } catch (error) {
        console.error('Eroare la preluarea datelor utilizatorului:', error);
      } finally {
        setLoading(false);  // Încărcarea s-a terminat
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return <div>Se încarcă datele utilizatorului...</div>;
  }

  if (!userData) {
    return <div>Eroare la încărcarea datelor utilizatorului</div>;
  }

  return (
    <div className='user-background-info'>
      <div id='user-page'>
        <div className='user-section'>
          <div className='user-image'>
            <img src={userData.profile_picture} alt="user" /> {/* Poza din baza de date */}
          </div>
          <div>
            <h1 className='data-color-user'>{userData.username}</h1>
            <h3 className='data-color-user'>{userData.email}</h3>
            <h3 className='data-color-user'>Account type</h3>
          </div>
        </div>
      </div>
      <div className='user-info-page'>
        <div id='lectii-certificate'>
          <h3 className='item-info-user'>Lectii parcurse</h3>
          <h3 className='item-info-user'>Certificate obtinute</h3>
        </div>
        <div id='punctaj-user'>Punctaj</div>
      </div>
    </div>
  );
};

export default UserPage;
