import React, { createContext, useReducer, useEffect } from 'react';
import Cookies from 'js-cookie'; // Pentru gestionarea cookie-urilor

// Inițializăm contextul
export const AuthContext = createContext();

// Reducer-ul pentru gestionarea autentificării
const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload, // Setăm token-ul din payload
      };
    case 'LOGOUT':
      // La logout, eliminăm token-ul din cookie-uri
      Cookies.remove('authToken', { path: '/' });
      return {
        ...state,
        isAuthenticated: false,
        token: null,
      };
    default:
      return state;
  }
};

// Starea inițială
const initialState = {
  isAuthenticated: false,
  token: null,
};

// Furnizorul de context pentru autentificare
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  // La inițializarea aplicației, verificăm dacă există un token în cookie-uri
  useEffect(() => {
    const checkAuth = async () => {
      const token = Cookies.get('authToken');
      if (token) {
        try {
          const response = await fetch('http://localhost:5000/api/check-auth', {
            method: 'GET',
            credentials: 'include', // Include cookie-urile în cerere
          });
  
          if (response.ok) {
            // Token valid, actualizează starea cu token-ul
            dispatch({ type: 'LOGIN', payload: token });
          } else {
            // Token invalid sau expirat
            dispatch({ type: 'LOGOUT' });
          }
        } catch (error) {
          console.error('Eroare la verificarea autentificării:', error);
          dispatch({ type: 'LOGOUT' });
        }
      }
    };
  
    checkAuth(); // Verifică autentificarea la încărcare
  }, []);
  

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
