import './registrer.css';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import SocialLogin from './SocialLogin';

export const Registrer = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatpass, setRepeatpass] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const validatePassword = (password) => {
      const minLength = 8;
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasNumber = /[0-9]/.test(password);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

      if (password.length < minLength) return 'Parola trebuie să aibă cel puțin 8 caractere';
      if (!hasUpperCase) return 'Parola trebuie să conțină cel puțin o literă mare';
      if (!hasLowerCase) return 'Parola trebuie să conțină cel puțin o literă mică';
      if (!hasNumber) return 'Parola trebuie să conțină cel puțin un număr';
      if (!hasSpecialChar) return 'Parola trebuie să conțină cel puțin un caracter special';
      return '';
    };

    if (!validateEmail(email)) {
      setErrorMessage('Adresa de email este invalidă');
      setIsLoading(false);
      return;
    }

    const passwordError = validatePassword(password);
    if (passwordError) {
      setErrorMessage(passwordError);
      setIsLoading(false);
      return;
    }

    if (password !== repeatpass) {
      setErrorMessage('Parolele nu se potrivesc');
      setIsLoading(false);
      return;
    }

    const userData = {
      username,
      email,
      password,
    };

    fetch('http://localhost:5000/api/accounts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || 'A apărut o eroare');
          });
        }
        return response.json();
      })
      .then((data) => {
        setUsername('');
        setEmail('');
        setPassword('');
        setRepeatpass('');
        
        // Mesaj care anunță utilizatorul să-și verifice e-mailul pentru confirmare
        setSuccessMessage('Contul a fost creat cu succes! Te rugăm să îți verifici adresa de e-mail pentru a confirma contul.');
        setErrorMessage('');
      })
      .catch((error) => {
        setErrorMessage(error.message);
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className='container-login'>
      <form className="registrer-form mt-4" onSubmit={handleSubmit}>
        <h2 className='registrer-title fs-1'>Creeaza cont!</h2>
        <label htmlFor="username" className="name-elemnts-form-reg"></label>
        <input
          type="text"
          id="username"
          name="username"
          className="input-reg"
          placeholder="Nume și prenume"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />

        <label htmlFor="email" className="name-elemnts-form-reg"></label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          className="input-reg"
          placeholder="Introdu o adresă de email"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor="password" className="name-elemnts-form-reg"></label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          className="input-reg"
          placeholder="Creează o parolă"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <label htmlFor="repeatpass" className="name-elemnts-form-reg"></label>
        <input
          type="password"
          id="repeatpass"
          name="repeatpass"
          value={repeatpass}
          className="input-reg"
          placeholder="Reintrodu parola"
          onChange={(e) => setRepeatpass(e.target.value)}
          required
        />
        <button type="submit" className="input-reg-submit" disabled={isLoading}>
          {isLoading ? 'Se înregistrează...' : 'Creează cont'}
        </button>
      </form>

      <b className='options-connect-sau'>SAU</b>
      <div className='options-connect'>
        <SocialLogin />
      </div>

      <div className='redirect-registrer'>
        <b className='redirect-reg-text'>Ai deja cont?</b>
        <Link to='/login' className='redirect-reg-link'>
          <b>Logheaza-te!</b>
        </Link>
      </div>

      <div className='redirect-registrer'>
        <b className='redirect-reg-text-terms'>Prin înregistrare, sunteți de acord cu
        <Link to='/Termeni' className='redirect-reg-terms'>
          <span> termenii si conditiile de utilizare</span>
        </Link>
        </b>
      </div>

      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};
