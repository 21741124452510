import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWebAwesome, faBlogger } from '@fortawesome/free-brands-svg-icons'; 
import { faSignOutAlt, faRocket, faHouse, faBook, faCircleUser, faUserPlus, faChevronRight, faChevronLeft, faUser, faCog, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../AuthContext';
import imageUser from '../imagesHome/myimage.png';
import './menu.css';

export const Menu = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [menuMobOpen, setMenuMobOpen] = useState(true);
  const location = useLocation();
  const { dispatch } = useContext(AuthContext);
  const [userData, setUserData] = useState({});
  const menuRef = useRef(null); // Ref pentru meniul mobil

  // Funcția pentru logout
  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  const [changeSecondMenu, setChangeSecondMenu] = useState(true);
  const openSecondMenu = () => {
    setChangeSecondMenu(!changeSecondMenu);
  };

  const navigateToSetari = useNavigate();
  const goToSetari = () => {
    navigateToSetari('/setari');
  };

  const navigateToAjutor = useNavigate();
  const goToAjutor = () => {
    navigateToAjutor('/ajutor');
  };

  const navigateToDashbord = useNavigate();
  const goToDashbord = () => {
    setChangeSecondMenu(!changeSecondMenu);
    navigateToDashbord('/userpage');
  };

  // Închide meniul mobil la fiecare schimbare de rută
  useEffect(() => {
    setMenuMobOpen(true);
  }, [location]);

  // Funcția care inversează starea meniului mobil
  const handleMenuMobile = () => {
    setMenuMobOpen(!menuMobOpen);
  };

  // Detectează clicurile din afara meniului mobil și închide meniul
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuMobOpen(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Fetch pentru a obține datele utilizatorului
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('http://localhost:5000/api/user', {
          method: 'GET',
          credentials: 'include',
        });
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        console.error('Eroare la obținerea datelor utilizatorului:', error);
      }
    };

    if (isAuthenticated) {
      fetchUserData();
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className='menu-items'>
        {isAuthenticated ? (
          <>
            <Link to='/userpage' className='name-menu-item'>
              <FontAwesomeIcon icon={faUser} className="mx-1" />
              Parcurs
            </Link>
            <Link to='/cursuri' className='name-menu-item'>
              <FontAwesomeIcon icon={faBook} className='mx-1' />
              Cursuri
            </Link>
            <Link to='/blog' className='name-menu-item'>
              <FontAwesomeIcon icon={faBlogger} className="mx-1" />
              Blog
            </Link>
          </>
        ) : (
          <>
            <Link to='/' className='name-menu-item'>
              <FontAwesomeIcon icon={faHouse} className="mx-1" />
              Acasa
            </Link>
            <Link to='/price' className='name-menu-item'>
              <FontAwesomeIcon icon={faRocket} className='mx-1' />
              Start
            </Link>
            <Link to='/blog' className='name-menu-item'>
              <FontAwesomeIcon icon={faBlogger} className="mx-1" />
              Blog
            </Link>
          </>
        )}
        {isAuthenticated ? (
          <>
            <Link to='/premium' className='name-menu-item-desktop-premium' onClick={() => setMenuMobOpen(true)}>
              <FontAwesomeIcon icon={faWebAwesome} className='mx-1' />
              Premium
            </Link>
          </>
        ) : (
          <>
            <Link to='/login' className='login-item-desktop'>
              <FontAwesomeIcon icon={faCircleUser} className="mx-1" />
              Log in
            </Link>
            <Link to='/registrer' className='signup-item-desktop'>
              <FontAwesomeIcon icon={faUserPlus} className="mx-1" />
              Sign up
            </Link>
          </>
        )}
      </div>

      <div className='mobile-menu' onClick={handleMenuMobile}>
        <div className={menuMobOpen ? 'item-mobile-ball' : 'item-mobile-ball-open'}></div>
        <div className={menuMobOpen ? 'item-mobile-ball' : 'item-mobile-ball-open'}></div>
        <div className={menuMobOpen ? 'item-mobile-ball' : 'item-mobile-ball-open'}></div>
      </div>

      <div ref={menuRef} className={menuMobOpen ? 'mob-menu-open-none' : 'mob-menu-open'}>
        {/* Conținutul meniului mobil */}
        {isAuthenticated ? (
          <>
            {/* Meniul pentru utilizatori autentificați */}
            <div className={changeSecondMenu ? 'show-second-menu' : 'hide-second-menu'}>
              <Link to='/userpage' className='name-menu-item-mob' onClick={() => setMenuMobOpen(true)}>
                <FontAwesomeIcon icon={faUser} className="mx-1" />
                Parcurs
              </Link>
              <Link to='/cursuri' className='name-menu-item-mob' onClick={() => setMenuMobOpen(true)}>
                <FontAwesomeIcon icon={faBook} className='mx-1' />
                Cursuri
              </Link>
              <Link to='/blog' className='name-menu-item-mob' onClick={() => setMenuMobOpen(true)}>
                <FontAwesomeIcon icon={faBlogger} className="mx-1" />
                Blog
              </Link>

              {/* Afișăm poza și numele utilizatorului din baza de date */}
              <button className='user-menu-button' onClick={openSecondMenu}>
                <div className='user-submenu-elements'>
                  <img src={userData.profile_picture || imageUser} alt='user' className='user-image-general-menu' />
                  <div>{userData.username || 'Nume Utilizator'}</div>
                </div>
                <FontAwesomeIcon icon={faChevronRight} className='arrow-sumbenu-user' />
              </button>

              <Link to='/premium' className='name-menu-item-mob-premium' onClick={() => setMenuMobOpen(true)}>
                <FontAwesomeIcon icon={faWebAwesome} className='mx-1' />
                Premium
              </Link>
            </div>
            <div className={changeSecondMenu ? 'hide-second-menu' : 'show-second-menu'}>
              <div onClick={() => setChangeSecondMenu(!false)} className='name-menu-item-mob-back'>
                <FontAwesomeIcon icon={faChevronLeft} className='arrow-sumbenu-user' />
                Back
              </div>

              <button className='user-menu-button' onClick={goToDashbord}>
                <div className='user-submenu-elements'>
                  <img src={userData.profile_picture || imageUser} alt='user' className='user-image-general-menu' />
                  <div>Profilul meu</div>
                </div>
              </button>

              <div className='name-menu-item-mob' onClick={goToSetari}>
                <FontAwesomeIcon icon={faCog} />
                Setari
              </div>
              <div className='name-menu-item-mob' onClick={goToAjutor}>
                <FontAwesomeIcon icon={faQuestionCircle} />
                Ajutor
              </div>

              <button onClick={handleLogout} className='name-menu-item-mob'>
                <FontAwesomeIcon icon={faSignOutAlt} />
                Logout
              </button>
            </div>
          </>
        ) : (
          <>
            {/* Meniul pentru utilizatori neautentificați */}
            <Link to='/' className='name-menu-item-mob' onClick={() => setMenuMobOpen(true)}>
              <FontAwesomeIcon icon={faHouse} className="mx-1" />
              Acasa
            </Link>
            <Link to='/price' className='name-menu-item-mob' onClick={() => setMenuMobOpen(true)}>
              <FontAwesomeIcon icon={faRocket} className='mx-1' />
              Start
            </Link>
            <Link to='/blog' className='name-menu-item-mob' onClick={() => setMenuMobOpen(true)}>
              <FontAwesomeIcon icon={faBlogger} className="mx-1" />
              Blog
            </Link>

            <Link to='/login' className='form-menu-tabel design-ligin' onClick={() => setMenuMobOpen(true)}>
              <FontAwesomeIcon icon={faCircleUser} className="mx-1" />
              Log in
            </Link>
            <Link to='/registrer' className='form-menu-tabel design-inregistrare' onClick={() => setMenuMobOpen(true)}>
              <FontAwesomeIcon icon={faUserPlus} className="mx-1" />
              Sign up
            </Link>
          </>
        )}
      </div>
    </>
  );
};
