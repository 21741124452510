import './setari.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

const Setari = () => {


   const redirectToDashbord = useNavigate()
 

    //usestate pentru afisarea fiecarui settings meniu in parte

    const [showInfoPersMob, setShowInfoPersMob] = useState('-9');


    const funcShowInfoPersMob = () => {
        setShowInfoPersMob('-9');
    }

    const funcShowChangePass = () => {
        setShowInfoPersMob('-9');
    }

    const funcShowPlanDetailed = () => {
        setShowInfoPersMob('-9');
    }

    const funcShowCloseAccount = () => {
        setShowInfoPersMob('-9');
    }


    const [pageDashbord, setPageDashbord] = useState(true);

    const renderpageDashbord = () => {
        if (pageDashbord === 'changePass') {
                return (
                <div className='container-mob-menu-width'>
                     <div className='sub-arrow-info' onClick={funcShowChangePass}>
                        <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronLeft} className='size-arrow-mob-user' /></div>
                        <h4 style={{textAlign: 'left', margin: '0 auto'}}>Schimbare parola</h4>
                    </div>
                    <form className='width-submenu-elements'>
                        <label className='set-block'>Parola curenta</label>
                        <div className='set-block'>
                            <input type='password' />
                            <button>Show</button>
                        </div>
                        
                        <label className='set-block'>Parola noua</label>
                        <div className='set-block'>
                            <input type='password' />
                            <button>Show</button>
                        </div>
                        
                        <input type='submit' value='Schimba parola' />
                    </form>
                </div>
            );
        }
            else if(pageDashbord === 'infoFact') {
                return (
                <div className='container-mob-menu-width'>
                    <div className='sub-arrow-info' onClick={funcShowPlanDetailed}>
                        <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronLeft} className='size-arrow-mob-user' /></div>
                        <h4 style={{textAlign: 'left', margin: '0 auto'}}>Detalii plan</h4>
                    </div>
                    <div className='width-submenu-elements'>
                        <div>Planul curent: <span>PRO</span></div>
                        <div>Urmatoarea data de facturare:</div>
                        <div>23.06.2025</div>
                    </div>
                    
                </div>);
                }
                else if(pageDashbord === 'deleteAccount') {
                return (
                <div className='container-mob-menu-width'>
                    <div className='sub-arrow-info' onClick={funcShowCloseAccount}>
                        <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronLeft} className='size-arrow-mob-user' /></div>
                        <h4 style={{textAlign: 'left', margin: '0 auto'}}>Inchidere cont</h4>
                    </div>
                    <div className='width-submenu-elements'>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate ea quam, tenetur esse possimus inventore provident, commodi fugiat doloribus facere corporis labore rerum corrupti ad incidunt, autem qui fuga eligendi.</p>
                        <div><input type='submit' value='Inchide contul' /></div>
                    </div>
                    
                </div>
            );
        }
        else {
                return (
                <div className='container-mob-menu-width'>
                    <div className='sub-arrow-info' onClick={funcShowInfoPersMob}>
                        <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronLeft} className='size-arrow-mob-user' /></div>
                        <h4 style={{textAlign: 'left', margin: '0 auto'}}>Informatii eprsonale</h4>
                    </div>
                    <div className='width-submenu-elements'>
                        <div>
                            <div>Imagine</div>
                            <div>
                                <div>
                                    <button>Incarca</button>
                                    <button>Seteaza</button>
                                </div>
                                <div>Info upload</div>
                            </div>  
                        </div>
                        <h6>Informatii actualizate cu succes</h6>
                        <form>
                            <label className='set-block'>Username</label>
                            <input type='text' className='set-block' value={'Alin dorobantu'} />
                            <label className='set-block'>Adresa de email</label>
                            <input type='email' className='set-block' value={'alindorobantu95@gmail.com'} />
                            <input type='submit' value='Salveaza' />
                        </form>
                    </div>

                </div>
            );
        }
        
           
    }

  return (
    <div className='settings-section'>
        <div className='settings-dashbord'>
            <div className='button-exit-change-menu-profile'>
                <div className='arrow-submenu-user-items' onClick={() => redirectToDashbord('/userpage')}><FontAwesomeIcon icon={faChevronLeft} className='size-arrow-mob-user' /></div>
               

                <button className='button-edit-user-profile-desktop-show-title'>Editeaza profil</button>
                    
                <h4 className='button-edit-user-profile-desktop-hide'>Editeaza profil</h4>
            </div>
            <div className='buttons-menu-settings'>
                <div className='button-edit-user-profile-mob' onClick={() => {setPageDashbord('true'); setShowInfoPersMob('0')}}>
                    
                    <button className='button-edit-user-profile-desktop-show'>Informatii personale</button>
                    
                    <h5 className='button-edit-user-profile-desktop-hide'>Informatii personale</h5>

                    <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronRight} className='size-arrow-mob-user' /></div>
                </div>
                <div className='button-edit-user-profile-mob' onClick={() => {setPageDashbord('changePass'); setShowInfoPersMob('0')}}>
                    <button className='button-edit-user-profile-desktop-show'>Schimbare parola</button>
                    <h5 className='button-edit-user-profile-desktop-hide'>Schimbare parola</h5>
                    <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronRight} className='size-arrow-mob-user' /></div>
                </div>
                <div className='button-edit-user-profile-mob' onClick={() => {setPageDashbord('infoFact'); setShowInfoPersMob('0')}}>
                    <button className='button-edit-user-profile-desktop-show'>Detalii plan</button>
                    <h5 className='button-edit-user-profile-desktop-hide'>Detalii plan</h5>
                    <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronRight} className='size-arrow-mob-user' /></div>
                </div>
                <div className='button-edit-user-profile-mob' onClick={() => {setPageDashbord('deleteAccount'); setShowInfoPersMob('0')}}>
                    <button className='button-edit-user-profile-desktop-show'>Inchidere cont</button>
                    <h5 className='button-edit-user-profile-desktop-hide'>Inchidere cont</h5>
                    <div className='arrow-submenu-user-items'><FontAwesomeIcon icon={faChevronRight} className='size-arrow-mob-user' /></div>
                </div>
            </div>
        </div>
        <div className='details-settings' style={{zIndex: showInfoPersMob}} id='set-important-submenu'>
            {renderpageDashbord()}
        </div>

        <div className='details-settings' id='set-important-submenu-second'>
            {renderpageDashbord()}
        </div>
        
        
    </div>
  )
}

export default Setari;