import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from '../AuthContext'; 
import { faSignOutAlt, faUser, faCog, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import './header.css';

const UserMenu = () => {
    const { dispatch } = useContext(AuthContext); // Accesăm dispatch pentru logout
    const [userMenu, setUserMenu] = useState(false);
    const [profilePicture, setProfilePicture] = useState(''); // Stare pentru imaginea de profil
    const menuRef = useRef(null); // Ref pentru meniul utilizatorului
    const imageRef = useRef(null); // Ref pentru imaginea utilizatorului

    // Funcția pentru logout - trimite acțiunea de logout
    const handleLogout = () => {
        dispatch({ type: 'LOGOUT' });
    };

    // Toggle pentru deschiderea/închiderea meniului
    const handleUserMenu = () => {
        setUserMenu(!userMenu);
    };

    // Funcția pentru a închide meniul
    const closeMenuUser = () => {
        setUserMenu(false);
    };

    // useEffect pentru a detecta click în afara meniului
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current && 
                !menuRef.current.contains(event.target) && 
                imageRef.current && 
                !imageRef.current.contains(event.target)
            ) {
                setUserMenu(false);
            }
        };

        if (userMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [userMenu]);

    // useEffect pentru a obține datele utilizatorului (inclusiv imaginea de profil)
    useEffect(() => {
        fetch('http://localhost:5000/api/user', {
            method: 'GET',
            credentials: 'include',
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to fetch user data');
            }
        })
        .then((data) => {
            // Setează imaginea de profil din baza de date sau folosește o imagine default
            setProfilePicture(data.profile_picture || '../imagesHome/myimage.png');
        })
        .catch((error) => {
            console.error('Error fetching user data:', error);
        });
    }, []);

    return (
        <div className='container-user-menu'>
            <div 
                className='user-picture-name'
                ref={imageRef} // Ref pentru imaginea utilizatorului
                onClick={handleUserMenu}
            >
                <div>
                    <img 
                        src={profilePicture} // Utilizează imaginea utilizatorului din baza de date
                        alt='user' 
                        className='image-user-central' 
                    />
                </div>
            </div>

            <div ref={menuRef} className={userMenu ? 'menu-user-icon' : 'menu-user-icon-hide'}>
                <Link to='./userpage'>
                    <button onClick={closeMenuUser} className='item-user-menu-icon'>
                        <FontAwesomeIcon icon={faUser} /> Profilul meu
                    </button>
                </Link>
                <Link to='./setari'>
                    <button onClick={closeMenuUser} className='item-user-menu-icon'>
                        <FontAwesomeIcon icon={faCog} /> Setari
                    </button>
                </Link>
                <Link to='./ajutor'>
                    <button onClick={closeMenuUser} className='item-user-menu-icon'>
                        <FontAwesomeIcon icon={faQuestionCircle} /> Ajutor
                    </button>
                </Link>

                <button onClick={() => { handleLogout(); closeMenuUser(); }} className='item-user-menu-icon'>
                    <FontAwesomeIcon icon={faSignOutAlt} className="mx-1" />
                    Logout
                </button>
            </div>
        </div>
    );
};

export default UserMenu;
