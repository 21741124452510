import React, { useEffect, useState } from 'react';
import './statusprogres.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCoins } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';

const StatusProgres = ({ width, onExitClick, coinCount }) => {
  const [progress, setProgress] = useState(width);
  const [displayedCoinCount, setDisplayedCoinCount] = useState(coinCount);
  const [isCoinAnimating, setIsCoinAnimating] = useState(false);

  useEffect(() => {
    setProgress(width);
  }, [width]);

  // Rulează animația doar dacă coinCount a crescut
  useEffect(() => {
    if (coinCount > displayedCoinCount) {
      setIsCoinAnimating(true);

      const timer = setTimeout(() => {
        setIsCoinAnimating(false); // Oprește animația după 300ms
        setDisplayedCoinCount(coinCount); // Actualizează displayedCoinCount la valoarea curentă a coinCount
      }, 300);

      return () => clearTimeout(timer); // Curăță timerul
    }
  }, [coinCount, displayedCoinCount]);

  return (
    <div className='general-section-progress-bar'>
      <div className='first-elements-header-progress-bar'>
        <div className='exit-lesson-name dim-prog-bar-1'>
          <FontAwesomeIcon
            icon={faTimes}
            className='arrow-change-lesson-x'
            onClick={onExitClick}
          />
        </div>

        <div className="container-hearts dim-prog-bar-2">
          <FontAwesomeIcon icon={faHeartSolid} className="heart-lives" />
          <FontAwesomeIcon icon={faHeartSolid} className="heart-lives" />
          <FontAwesomeIcon icon={faHeartSolid} className="heart-lives-gray" />
        </div>

        <div className='procent-lesson dim-prog-bar-3'>
          <div className={`dim-prog-bar ${isCoinAnimating ? 'bounce-animation' : ''}`}>
            <span className="coin-score-box">{displayedCoinCount}</span>
            <FontAwesomeIcon icon={faCoins} className="coin-edit-score" />
          </div>
        </div>
      </div>

      <div className='section-progress-bar-lesson'>
        <div className='progress-bar-container'>
          <input
            type='range'
            min='0'
            max='100'
            value={progress}
            className='progress-bar-range'
            style={{ '--progress': `${progress}%` }}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default StatusProgres;
