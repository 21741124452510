import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext'; // Contextul pentru autentificare
import { Header } from './header/Header';
import { Home } from './content/Home';
import { About } from './footer/About';
import Diploma from './diploma/Diploma';
import { Registrer } from './content/Registrer';
import { Login } from './content/Login';
import ConfirmationPage from './content/ConfirmationPage';
import { Showlessons } from './content/Showlessons';
import { Footer } from './footer/Footer';
import './App.css';
import { LessonsContainer } from './content/lessons/LessonsContainer';
import Price from './header/offer/Price';
import NextStepOffer from './header/offer/NextStepOffer';
import Viewplans from './header/offer/Viewplans';
import Premium from './header/offer/Premium';
import Finaloffer from './header/offer/Finaloffer';
import StartPremiumTrial from './header/offer/StartPremiumTrial';
import Paymentpage from './header/offer/Paymentpage';
import OfferDiscount from './header/offer/OfferDiscount';
import Facturare from './header/offer/Facturare';





import Blog from './content/Blog';
import ScrollToTop from './ScrollToTop';
import Chaptercontainer from './Chaptercontainer';
import UserPage from './users/UserPage';
import Setari from './users/Setari';
import Ajutor from './users/Ajutor';
import ProtectedRoute from './ProtectedRoute';
import Cursuri from './content/Cursuri';
import Demo from './content/Demo';
import FAQ from './footer/FAQ';
import Termeni from './footer/Termeni';
import Politica from './footer/Politica';
import Politicacookies from './footer/Politicacookies';

import CookieConsent from './CookieConsent'; 

import StripeWrapper from './payment/PaymentForm'; // Importăm componenta de plată

function AppContent() {
  const location = useLocation();
  const lessonIsActive = location.pathname === '/chaptercontainer';
  const showLessonIsActive = location.pathname === '/showlessons';
  const premiumActive = location.pathname === '/premium';
  const NextStepOfferActive = location.pathname === '/neststepoffer';
  const ViewplansActive = location.pathname === '/viewplans';
  const FinalofferActive = location.pathname === '/finaloffer';
  const PaymentpageActive = location.pathname === '/paymentpage';
  const StartPremiumTrialActive = location.pathname === '/startpremiumtrial';
  const OfferDiscountActive = location.pathname === '/offerdiscount';
  const FacturareActive = location.pathname === '/facturare';

  

  return (
    <>
      {!(lessonIsActive || premiumActive || NextStepOfferActive || ViewplansActive || FinalofferActive || PaymentpageActive || StartPremiumTrialActive || OfferDiscountActive || FacturareActive) && <Header />}
      <div className="d-flex flex-column v-100">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/confirmationemail" element={<ConfirmationPage />} />
          <Route path="/registrer" element={<Registrer />} />
          <Route path="/showlessons" element={<Showlessons />} />
          <Route path="/lessonscontainer" element={<LessonsContainer />} />
          <Route path="/price" element={<Price />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/chaptercontainer" element={<Chaptercontainer />} />
          <Route path="/cursuri" element={<Cursuri />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/diploma" element={<Diploma />} />
          <Route path="/termeni" element={<Termeni />} />
          <Route path="/politica" element={<Politica />} />
          <Route path="/politicacookies" element={<Politicacookies />} />
          <Route path="/pay" element={<StripeWrapper />} /> {/* Ruta pentru plată */}
          <Route path="/premium" element={<Premium />} /> {/* Ruta pentru plată */}
          <Route path="/neststepoffer" element={<NextStepOffer />} />
          <Route path="/viewplans" element={<Viewplans />} />
          <Route path="/finaloffer" element={<Finaloffer />} />
          <Route path="/startpremiumtrial" element={<StartPremiumTrial />} />
          <Route path="/offerdiscount" element={<OfferDiscount />} />
          <Route path="/facturare" element={<Facturare />} />

    

         
          <Route path="/paymentpage" element={<Paymentpage />} />

          {/* Rute protejate */}
          <Route
            path="/userpage"
            element={<ProtectedRoute element={<UserPage />} />}
          />
          <Route
            path="/setari"
            element={<ProtectedRoute element={<Setari />} />}
          />
          <Route
            path="/ajutor"
            element={<ProtectedRoute element={<Ajutor />} />}
          />
        </Routes>
      </div>

      {!(lessonIsActive || showLessonIsActive || premiumActive || NextStepOfferActive || ViewplansActive || FinalofferActive || PaymentpageActive || StartPremiumTrialActive || OfferDiscountActive || FacturareActive) && <Footer />}

    </>
  );
}

// Componenta principală
function App() {
  const { dispatch } = useContext(AuthContext);  // Obține dispatch din AuthContext

  // La inițializarea aplicației, verificăm autentificarea
  useEffect(() => {
    fetch('http://localhost:5000/api/check-auth', {
      method: 'GET',
      credentials: 'include',  // Asigură-te că trimiți cookie-urile către server
    })
    .then(response => {
      if (response.ok) {
        return response.json();  // Dacă token-ul este valid, obținem datele utilizatorului
      } else {
        throw new Error('Not authenticated');
      }
    })
    .then(data => {
      // Actualizăm starea autentificării cu token și datele utilizatorului
      dispatch({ type: 'LOGIN', payload: { token: data.token, user: data.user } });
    })
    .catch(error => {
      console.log('Not authenticated:', error);
      dispatch({ type: 'LOGOUT' });  // Dacă nu suntem autentificați, delogăm utilizatorul
    });
  }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />
      <AppContent />
      <CookieConsent /> {/* Afișăm componenta de consimțământ cookie */}
    </Router>
  );
}

export default App;
