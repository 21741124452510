import { Link } from 'react-router-dom';
import './cursuri.css';
const Cursuri = () => {
  return (
    <div className="container-select-course">
        <div className="item-course">
            <h3 className="mb-4">Bazele contabilitatii</h3>
            <Link to='/showlessons'>
                <button className='select-course'>START</button>
            </Link>
        </div>
        <div className="item-course">
            <h3 className="mb-4">Contabilitate financiara</h3>
            <button className='select-course'>IN PROGRESS</button>
        </div>
    </div>
  )
}

export default Cursuri;