import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import StatusProgres from './StatusProgres';
import './chaptercontainer.css';
import finishLesson from './imagesHome/finish_lesson.gif';


import levelUp from './header/images/level-up.mp3';
import error from './header/images/error.mp3';



const Chaptercontainer = () => {

  const [coinCount, setCoinCount] = useState(0);

 




  // Componentă pentru alerta personalizată
const CustomAlert = ({ message, onConfirm, onCancel }) => {
  return (
    <div style={styles.overlay}>
      <div style={styles.alertBox}>
        {message}
        <div style={styles.buttonContainer}>
          <button onClick={onConfirm} style={styles.button}>Da</button>
          <button onClick={onCancel} style={styles.button}>Nu</button>
        </div>
      </div>
    </div>
  );
};



const [showAlert, setShowAlert] = useState(false);
const navigate = useNavigate(); // Inițializează navigate

const handleExitClick = () => {
  setShowAlert(true);
};

const handleConfirmExit = () => {
  setShowAlert(false);
  navigate('/showlessons'); // Redirecționează către pagina /showlessons
};

const handleCancelExit = () => {
  setShowAlert(false);
};

const messageAlertClose = 
<div>
  <h3>Nu renunța!</h3>
  <p>Dacă părăsești această lecție, vei pierde progresul. Ești sigur?</p>
</div>;






  const location = useLocation();
  const { chapterId, lessonId } = location.state || {}; // Primește chapterId și lessonId
  const [lessonData, setLessonData] = useState(null);
  const [nextQuestion, setNextQuestion] = useState(0);

  const [isLessonFinished, setIsLessonFinished] = useState(false); // Stare pentru finalizarea lecției


  const [changeColorButton, setChangeColorButton] = useState(false);

  const [messaheColor, setMessaheColor] = useState(false);


  //stateuri pentru raspunsul corect

  const [simpleCorrectAnswer, setSimpleCorrectAnswer] = useState(false)




  // Funcția pentru a finaliza lecția și redirecționare

  const navigateToShowLesson = useNavigate();
  const finalizeLesson = () => {

    const lessonProgress = JSON.parse(localStorage.getItem('lessonProgress')) || {};

    const updatedProgress = {
      ...lessonProgress,
      [lessonId]: { ...lessonProgress[lessonId], completed: true },
      [lessonId + 1]: { ...lessonProgress[lessonId + 1], unlocked: true },
    };

    // Salvează progresul actualizat în localStorage
    localStorage.setItem('lessonProgress', JSON.stringify(updatedProgress));

    // Navighează înapoi la Showlessons și trimite progresul prin state
    navigateToShowLesson('/showlessons',{ state: { updatedProgress } }); // Redirecționează la LessonContainer
  };


//functia pentru calcularea progresului

const calculateProgress = () => {
  if (!lessonData || !lessonData.questions || lessonData.questions.length === 0) {
    return 0; // Dacă datele nu sunt încă încărcate, progresul este 0%
  }

  const totalQuestions = lessonData.questions.length - 1; // Ne asigurăm că progresul ajunge la 100% la penultima întrebare

  const progress = (nextQuestion / totalQuestions) * 100; // Calculăm procentajul progresului

  return Math.min(progress, 100); // Ne asigurăm că nu depășește 100%
};







  //state pentru setarea mesajului

    // State pentru a stoca răspunsurile mutate
    const [dataReceived, setDataReceived] = useState([]);

 
// Funcția pentru a muta sau elimina răspunsul selectat
const toggleItem = (answer) => {
  setDataReceived((prev) => {
    let updatedData;
    
    if (prev.includes(answer)) {
      // Dacă răspunsul este deja mutat, îl eliminăm din `dataReceived`
      updatedData = prev.filter(item => item !== answer);
    } else {
      // Dacă răspunsul nu este mutat, îl adăugăm
      updatedData = [...prev, answer];
    }

    // Activăm sau dezactivăm butonul în funcție de conținutul array-ului `dataReceived`
    setDesableButton(updatedData.length === 0);  // Dezactivat dacă e gol, activat altfel
    setTextButton('Verifica');
    setMessaheColor(false);
    setChangeColorButton(true);


    return updatedData;
  });
};

// Verificăm dacă avem deja un răspuns mutat pentru acel index
const renderAnswer = (index) => {
  
  return dataReceived[index] ? dataReceived[index] : ''; // Afișează răspunsul dacă există
  
};
     
 


const toNextQuestion = () => {
  setNextQuestion((prev) => {
    const newNextQuestion = prev + 1;
    if (newNextQuestion >= lessonData.questions.length) {
      setIsLessonFinished(true); // Lecția este finalizată
    }
    return newNextQuestion;
  });
};



  useEffect(() => {
    if (chapterId && lessonId) {
      // Cererea fetch pentru lecția selectată
      fetch(`http://localhost:5000/chapter/${chapterId}/lesson/${lessonId}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('A apărut o eroare');
        }
        return response.json();
      })
      .then(data => {
        setLessonData(data);
      })
      .catch(err => {
        console.error(`Error: ${err}`);
      });
    }
  }, [chapterId, lessonId]);


// Folosim un string (sau null) pentru simpleSelectType, deoarece utilizatorul selectează o singură opțiune
const [simpleSelectType, setSimpleSelectType] = useState(null); //usestateul pentru stocarea raspusurilor simple select
const [userInput, setUserInput] = useState({});  // Răspunsul introdus de utilizator
const [selectedAnswers, setSelectedAnswers] = useState([]); // Răspunsurile bifate de utilizator
const [message, setMessage] = useState('');
const [textButton, setTextButton] = useState('')

useEffect(() => {
  if(isLessonFinished) {
  setTextButton('Finalizeaza lectia');

} else {
  setTextButton('ContinuaF')
}
  
}, [isLessonFinished])


const [isAnswerChecked, setIsAnswerChecked] = useState(false); //urmaresc daca raspunsul a fost verificat


const buttonTextChange = () => {

  if (!lessonData || !lessonData.questions || lessonData.questions.length === 0) {
    return <p>Loading lesson...</p>;
  }
  const currentQuestion = lessonData.questions[nextQuestion]; 

  switch (currentQuestion.type) {
    case 'explanationLesson':
      setTextButton('Continua00000');
      break;

    case 'simpleSelect':
      setTextButton('vaerifica 1')
      setChangeColorButton(true)
      break;

    case 'manualInput':
      
      break;

    case 'dragAndDrop':
      setTextButton('Verifica');
      setMessaheColor(false);
      
      break;

    case 'multipleSelect':
      setTextButton('Verifica')
      setMessaheColor(false);
      break;

    default:
      
  }
};


const [desableButon, setDesableButton] = useState(false);
//functia pentru dezactivarea butonului



const isButtonEnabled  = () => {
  if (!lessonData || !lessonData.questions || lessonData.questions.length === 0) {
    return <p>Loading lesson...</p>;
  }
  const currentQuestion = lessonData.questions[nextQuestion]; 

  switch (currentQuestion.type) {
    case 'explanationLesson':
      setDesableButton(true);
      break;

    case 'simpleSelect':
      
      break;

    case 'manualInput':
      
      break;

    case 'dragAndDrop':
     
    
      break;

    case 'multipleSelect':
     
      break;

    default:
      
  }

};






  //functia de verificare a raspunsurilor
  
  const verifyAnswer = () => {

       if (!lessonData || !lessonData.questions || lessonData.questions.length === 0) {
      return <p>Loading lesson...</p>;
    }
    const currentQuestion = lessonData.questions[nextQuestion]; 


  
    

    switch (currentQuestion.type) {
      case 'explanationLesson':
        toNextQuestion();
        
        
        break;
  
      case 'simpleSelect':
        if (!isAnswerChecked) {
          // Prima fază: Verifică răspunsul
          if (currentQuestion.correctAnswer === simpleSelectType) {
            setMessage('Răspuns corect!');  // Mesaj de succes
            const audioUp = new Audio(levelUp);
                audioUp.play();
            setSimpleCorrectAnswer(false);
            setDesableButton(false);
            setCoinCount(prev => prev + 1);
          } else {
            setMessage('Răspuns greșit!');  // Mesaj de eroare
            const audioErr = new Audio(error);
                audioErr.play();
            setSimpleCorrectAnswer(currentQuestion.correctAnswer);
  
            setMessaheColor(true);
          }
          setIsAnswerChecked(true);  // Marchez răspunsul ca verificat
          setTextButton('Continua23ss');  // Schimb textul butonului la "Continua"
         
          setChangeColorButton(false);
        } else {
          // A doua fază: Trecem la următoarea întrebare
          setIsAnswerChecked(false);  // Resetează starea de verificare
          setSimpleSelectType(null);  // Resetează răspunsul selectat
          setMessage('');  // Resetează mesajul
          setTextButton('continua1234');  // Resetează textul butonului la "Verifică"
          toNextQuestion();  // Trecem la următoarea întrebare
         
        }

        
        
        break;
  
      case 'manualInput':
        if (!isAnswerChecked) {
          // Verificăm dacă toate răspunsurile sunt corecte
          let allCorrect = true;
      
          currentQuestion.correctAnswer.forEach((answer, index) => {
            // Comparam răspunsul utilizatorului cu codul corect
            if (Number(userInput[index]) !== answer.code) {
              allCorrect = false;
            }
          });
      
          if (allCorrect) {
            setMessage('Răspuns corect!');
            const audioUp = new Audio(levelUp);
                audioUp.play();
            setSimpleCorrectAnswer(false);
            setCoinCount(prev => prev + 1);
          } else {
            setMessage('Răspuns greșit!');
            const audioErr = new Audio(error);
            audioErr.play();
            setSimpleCorrectAnswer(currentQuestion.correctAnswer.map((newAnswer) => {
             return <div>{newAnswer.code}:{newAnswer.label}</div>
            }));


      
            setMessaheColor(true);
          }
      
          setIsAnswerChecked(true);  // Marchez răspunsul ca verificat
          setTextButton('Continuă');  // Schimb textul butonului la "Continuă"
          setChangeColorButton(false)
          setDesableButton(false); // Activăm butonul după verificare pentru a permite utilizatorului să continue
         
      
        } else {
          // A doua fază: Trecem la următoarea întrebare
          setIsAnswerChecked(false);  // Resetează starea de verificare
          setUserInput({});  // Resetează input-urile utilizatorului
          setMessage('');  // Resetează mesajul
          setTextButton('Continuă');  // Resetează textul butonului la "Verifică"
          setChangeColorButton(false);
          toNextQuestion();  // Trecem la următoarea întrebare
        }



        
        break;
  
      case 'dragAndDrop':
        if (!isAnswerChecked) {
          // Verificăm dacă răspunsurile mutate de utilizator sunt corecte
          if (JSON.stringify(dataReceived) === JSON.stringify(currentQuestion.correctAnswer)) {
            setMessage('Răspuns corect!');
            const audioUp = new Audio(levelUp);
                audioUp.play();
            setSimpleCorrectAnswer(false);
            setCoinCount(prev => prev + 1);
          } else {
            setMessage('Răspuns greșit!');
            const audioErr = new Audio(error);
            audioErr.play();
           

            setSimpleCorrectAnswer(currentQuestion.correctAnswer.map((newAnswer) => {
              return <div>{newAnswer}</div>
             }));

            setMessaheColor(true);
            
          }
      
          setIsAnswerChecked(true);  // Marchez răspunsul ca verificat
          setTextButton('Continuă');  // Schimb textul butonului la "Continuă"
          setChangeColorButton(false);
            // Activez butonul pentru a permite trecerea la următoarea întrebare
      
        } else {
          // A doua fază: Trecem la următoarea întrebare
          setIsAnswerChecked(false);  // Resetează starea de verificare
          setDataReceived([]);  // Resetează răspunsurile mutate
          setMessage('');  // Resetează mesajul
          setTextButton('Continuă');  // Resetează textul butonului la "Verifică"
          setChangeColorButton(false);
            // Dezactivează butonul pentru a forța verificarea înainte de a continua
          toNextQuestion();  // Trecem la următoarea întrebare
        }

        
        break;
  
      case 'multipleSelect':
        if (!isAnswerChecked) {
          // Verificăm dacă răspunsurile selectate de utilizator sunt corecte
          const sortedSelected = [...selectedAnswers].sort();  // Sortăm o copie pentru comparație
          const sortedCorrect = [...currentQuestion.correctAnswer].sort();  // Sortăm răspunsurile corecte
          
          if (JSON.stringify(sortedSelected) === JSON.stringify(sortedCorrect)) {
            setMessage('Răspuns corect!');
            const audioUp = new Audio(levelUp);
                audioUp.play();
            setSimpleCorrectAnswer(false);
            setCoinCount(prev => prev + 1);
          } else {
            setMessage('Răspuns greșit!');
            const audioErr = new Audio(error);
                audioErr.play();
            setSimpleCorrectAnswer(currentQuestion.correctAnswer);
            setMessaheColor(true);
          }
      
          setIsAnswerChecked(true);  // Marchez răspunsul ca verificat
          setTextButton('Continuă');  // Schimb textul butonului la "Continua"
          setChangeColorButton(false);
        } else {
          // A doua fază: Trecem la următoarea întrebare
          setIsAnswerChecked(false);  // Resetează starea de verificare
          setSelectedAnswers([]);  // Resetează răspunsurile selectate
          setMessage('');  // Resetează mesajul
          setTextButton('Continuă');  // Resetează textul butonului la "Verifică"
          setChangeColorButton(false);
          toNextQuestion();  // Trecem la următoarea întrebare
        }
        break;

        case 'lessonResume':
        toNextQuestion();
        setTextButton('Verifică333333333');
        setMessaheColor(false);
        break;

      default:
       <p>'Tipul întrebării nu este recunoscut'</p> 
    }
  };
  

  const handleInputChange = (index, value) => {
    const updatedInput = {
      ...userInput,
      [index]: value  // Actualizăm răspunsul pentru fiecare index
    };
    
    setUserInput(updatedInput);

   
  
    // Verificăm dacă s-au introdus date în cel puțin un câmp de input
    const hasData = Object.values(updatedInput).some(val => val.trim() !== '');
    
    // Schimbăm textul butonului dacă există input
    if (hasData) {
      setTextButton('Verifică888888888');
      setMessaheColor(false);
      setChangeColorButton(true);
    } else {
      setTextButton('Continua12');
    }
  };
 



// Funcția pentru a actualiza răspunsurile selectate
const handleCheckboxChange = (option) => {
  setSelectedAnswers(prevSelected => {
    let updatedSelected;
    
    if (prevSelected.includes(option)) {
      // Dacă opțiunea este deja selectată, o eliminăm din array
      updatedSelected = prevSelected.filter(answer => answer !== option);
    } else {
      // Adăugăm opțiunea selectată
      updatedSelected = [...prevSelected, option];
    }
    
    // Verificăm dacă mai sunt opțiuni selectate
    setDesableButton(updatedSelected.length === 0); // Dezactivează butonul dacă nu e nimic selectat
    return updatedSelected; // Returnează lista actualizată de răspunsuri selectate
  });

  // După fiecare schimbare, setăm textul butonului la "Verifică"
  setTextButton('Verifică0000000000');
  setMessaheColor(false);
  setChangeColorButton(true);
};

const [finalMessage, setFinalmessage] = useState(false);

// Afișarea în funcție de tipul întrebării
const renderContent = () => {
   

    if (!lessonData || !lessonData.questions || lessonData.questions.length === 0) {
      return <p>Loading lesson...</p>;
    }

    const currentQuestion = lessonData.questions[nextQuestion];

     // Verificăm dacă am ajuns la ultima întrebare
  if (nextQuestion >= lessonData.questions.length) {
   
    setTimeout(() => {
      setFinalmessage(true)

    }, 3000)
    return (
      <div className='d-flex flex-column justify-content-center align-items-center'>
        
      <img src={finishLesson} alt='finish' className='my-3' style={{width: '60%'}} />
        
       {finalMessage && <h1 className='my-3 fw-bold fs-1'>Lectie completa!</h1>}
      
  
      </div>
  );  // Mesaj de finalizare
  }
  
   
    switch (currentQuestion.type) {

        case 'explanationLesson':
        return (
          <div className='explanation-lesoon-container'>
           
            <h4 className='tittle-sescrition-lesson'><strong>{currentQuestion.titleExplanation}</strong> </h4>
            
            <div className='description-lesson-text'>
              {currentQuestion.descriptionExplanation.map((explanation, index) => (
                <p key={index} className='my-3 text-size-emplanation'>
                  {explanation}
                </p>
              ))}
              
            </div>
          </div> 
        );

      case 'simpleSelect':
        return (
          <div className='d-flex justify-content-center flex-column align-items-center w-100'>
           
            <h4 className='tittle-sescrition-lesson title-simple-select-align'><strong>{currentQuestion.question}</strong> </h4>
            <div className='simple-select-buttons-description'>
              
              {currentQuestion.options.map((option, index) => (
                <button 
                  key={index} 
                  className={simpleSelectType === option ? 'simple-Button-select-colored' : 'simple-Button-select'}
                  onClick={() => {setSimpleSelectType(option); buttonTextChange(); setDesableButton(false)}}  // Setează opțiunea selectată
                  disabled={isAnswerChecked}
               >
                  <p>{option}</p>
                </button>
              ))}
            </div>

            <h5 className='mt-5 mx-auto opacity-25 disabled'>Selecteaza un raspuns!</h5>
          </div>
        );

      case 'manualInput':
        return (
          <div className='explanation-lesoon-container'>
            <h4 className='tittle-sescrition-lesson'><strong>{currentQuestion.question}</strong></h4>

            {/* Generăm input-uri pentru fiecare răspuns corect */}
            <div className='align-input-items'>
            {currentQuestion.correctAnswer.map((answer, index) => (
              <div className=''>
                <div key={index} className='input-item-answer-type'>
                  <input
                    className='answer-input-item-complete'
                    type="text" 
                    maxLength='4'
                    value={userInput[index] || ''}  // Afișează valoarea introdusă de utilizator
                    onChange={(e) => {handleInputChange(index, e.target.value); setDesableButton(false)}}  // Actualizează răspunsul
                    
                    disabled={isAnswerChecked}  // Dezactivează input-ul după verificare
                  />
                  
                  <p className='input-title-element-answer'>{answer.label}</p>  {/* Afișează label-ul pentru fiecare input */}
                  </div>
                </div>
              
            ))}
            </div>

            <h4 className='mt-5 opacity-25 disabled'>Introdu raspunsurile numerice!</h4>
            
          </div>
        );

      case 'dragAndDrop':
        return (
          <div className='d-flex flex-column justify-content-center align-items-center'>
            
            <h4 className='tittle-sescrition-lesson'><strong>{currentQuestion.question}</strong></h4>
          <div className='cadru-lesson-grag-drop'>
      
            {currentQuestion.options.map((option, index) => (
            <button key={index} className='drag-lesson-answer'>
              {/* Afișează răspunsul din dataReceived în prima căsuță disponibilă */}
              <div className='drag-option' onClick={() => {toggleItem(renderAnswer(index))}}>
                {renderAnswer(index)}
              </div> 
              <div className='drag-text'>
                <p>{option.label}</p>
              </div>
              
            </button>
            ))}
            
            </div>

            <div className='drag-background-options'>
              {currentQuestion.correctAnswer.map((answer, index) => (
                <button key={index} 
                className='buttons-drag-options'
                onClick={() => toggleItem(answer)}  // Mută sau elimină răspunsul selectat la click
                disabled={dataReceived.includes(answer)}  // Dezactivează dacă răspunsul este mutat
              >
                  <div>{answer}</div>
                </button>
              ))}
              </div>
            <h5 className='mt-5 opacity-25 disabled'>Selecteaza raspunsurile corecte!</h5>
          </div>
        );

      case 'multipleSelect':
        return (
          <div className='explanation-lesoon-container'>
            
            <h4 className='tittle-sescrition-lesson'><strong>{currentQuestion.question}</strong></h4>
            <div className='multiple-answer-lessons'>
              {currentQuestion.options.map((option, index) => (
                <div key={index} className='align-multiple-items'>
                  <div>
                  <input
                  className='check-box-multiple-answer'
                    type="checkbox"
                    id={`option-${index}`}
                    checked={selectedAnswers.includes(option)}  // Bifează checkbox-ul dacă opțiunea este selectată
                    onChange={() => handleCheckboxChange(option)}  // Actualizează răspunsul selectat
                    disabled={isAnswerChecked}  // Dezactivează după verificare
                  />
                  </div>
                  <h6 htmlFor={`option-${index}`}>{option}</h6>
                </div>
              ))}
            </div>

            <h5 className='mt-5 mx-auto opacity-25 disabled'>Bifeaza raspunsurile corecte!</h5>
          </div>
        );

        case 'lessonResume':
        return (
          <div className='explanation-lesoon-container'>
            
            <h4 className='tittle-sescrition-lesson'><strong>{currentQuestion.titleExplanation}</strong></h4>
            <div>
              {currentQuestion.descriptionExplanation.map((description, index) => (
                <div key={index}>
                  {description}
                </div>
              ))}
            </div>
          </div>
        );

      default:
        return <p>Tipul întrebării nu este recunoscut.</p>;
    }

    

  };



  

  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
       
       <div className='position-progress-bar'>
       {!isLessonFinished && <StatusProgres width={calculateProgress()}
       coinCount={coinCount}
       onExitClick={handleExitClick}  />}





       </div>
      <div className='position-main-lesson'>
        
        {renderContent()}
        
      </div>
      <div className='position-button-lesson'>
      <h3 className={messaheColor?'next-message-answer-wrong':'next-message-answer'}>{message}</h3>

      <p>{isAnswerChecked && simpleCorrectAnswer}</p>
        <button 
          className={desableButon?'button-next-quiz-desabled':changeColorButton?'button-next-quiz-verify':'button-next-quiz'} 
          onClick={() => {
            if (isLessonFinished) {
              finalizeLesson(); // Dacă lecția este finalizată, redirecționează
            } else {
              verifyAnswer(); // Dacă lecția nu este finalizată, trece la următoarea întrebare
              isButtonEnabled(); // Verifică dacă butonul trebuie activat sau dezactivat
            }
          }}
          disabled={desableButon}
        >
          {textButton} {/* Schimbă textul dinamic al butonului */}
      </button>
    </div>
     {/*Alerta de parasire a lectiei */}
    {showAlert && (
      <CustomAlert
        message={messageAlertClose}
        onConfirm={handleConfirmExit}
        onCancel={handleCancelExit}
      />
    )}

 
    </div>
  );



  
};



const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    pointerEvents: 'auto',
  },
  alertBox: {
    backgroundColor: 'white',
    padding: '40px',
    borderRadius: '5px',
    textAlign: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    margin: '0 30px',
    zIndex: 1100,
  },
  buttonContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '150px',
    margin: '0 auto',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: 'rgb(22, 199, 158)',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};


export default Chaptercontainer;